/* App.css */
#root {
  text-align: center;
  margin: 1.2rem;
  background-color: black;
}

body {
  background-color: black;
  color: #eee
}

h6 {
  color: green
}

#nav-menu {
  display: none;
}

nav .nav-bar {
  display: block;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

#profile-pic {
  border-radius: 50%;
  height: 200px;
  border-radius: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}